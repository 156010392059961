export var babyMonthList = ['baby-week-21', 'baby-week-26', 'baby-week-30', 'baby-week-34', 'baby-week-39', 'baby-week-43', 'baby-week-47', 'baby-week-52'];
export var pregnancyWeekTabs = {
  '1st trimester': {
    start: 0,
    end: 13
  },
  '2de trimester': {
    start: 13,
    end: 27
  },
  '3de trimester': {
    start: 27,
    end: 42
  }
};
export var pregnancyWeekList = Array.from({
  length: 42
}).map(function (_, idx) {
  return {
    number: idx + 1,
    link: "/week-".concat(idx + 1),
    label: idx === 0 ? 'Week' : 'Weken'
  };
});
export var babyWeekTabs = {
  '1e jaar': {
    start: 0,
    end: 25
  },
  '2de jaar': {
    start: 25,
    end: 37
  },
  '3rde jaar': {
    start: 37,
    end: 49
  },
  '4de jaar': {
    start: 49,
    end: 61
  }
};
export var babyWeekList = Array.from({
  length: 25
}).map(function (_, idx) {
  return {
    number: idx < 17 ? idx : idx - 12,
    link: idx < 17 ? "/baby-week-".concat(idx + 1) : "/".concat(babyMonthList[idx - 17]),
    label: idx === 1 ? 'Week' : idx < 17 ? 'Weken' : 'Maanden'
  };
}).concat(Array.from({
  length: 36
}).map(function (_, idx) {
  return {
    number: idx + 13,
    link: "/".concat(idx < 11 ? 'dreumes' : idx < 35 ? 'peuter' : 'kleuter', "-").concat(idx + 13, "-maanden"),
    label: 'Maanden'
  };
}));
export function getWeekContentLink(user) {
  if (!user.username || user.phaseNumeric <= 0) return;
  if (user.accountType === 'PARTNER') return "/partner-week-".concat(user.phaseNumeric);
  if (user.status === 'PREGNANT') return "/week-".concat(user.phaseNumeric);
  if (user.phaseNumeric <= 17) return "/baby-week-".concat(user.phaseNumeric);
  var month = Math.ceil(user.phaseNumeric / 4);
  if (month > 12) return;
  return "/".concat(babyMonthList[month - 5]);
}